import React, {  useMemo } from "react";
import "./DoctorsTable.css";
import { TbUserEdit } from "react-icons/tb"
import { NoDataFound } from "../Common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteDoctor } from "../../utils/doctorUtils/doctorUtils";

const DoctorsInformation = ({setEditData,setOpen,doctors,setDoctorsFn, selected}) => {
  const delDoctor = (doctorId) => {
    const confirmed = window.confirm("Are you sure you want to this action ?");
    if (!confirmed) return;
    deleteDoctor(doctorId).then(res=> setDoctorsFn()).catch(err=>console.log(err));
  };
  const tableHeadings = useMemo(
      () => {
          if (selected === "Requested") {
              return ["Name", "Doctor-Id", "Email", "Phone", "Experience"]
          }
          else {
             return ["Name", "Doctor-Id", "Email", "Phone", "Experience", "Edit", "Delete"] 
          }
      }, [selected]);
  return (
    <div className="doctors-information-container flex-col mt-5">
      <div className="di-table-container max-h-[400px]">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {doctors.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-blue-100">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th scope="col" key={index} className="di-head-row">
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 max-h-[400px] overflow-y-auto">
                    {doctors.map((doctor, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center gap-4">
                            <div className="flex-shrink-0 h-9 w-9 justify-center items-center border rounded-full flex">
                              <img
                                className="rounded-full h-9 w-9 object-cover"
                                src={doctor?.doctor_image}
                                alt="doctor_profile"
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              {doctor?.name}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.doctor_id || (
                              <span className="text-red-600">Not Approved</span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {doctor?.email}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 ">
                            {doctor?.phone_number}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900 capitalize">
                            {doctor?.experience} <span>Year</span>
                          </div>
                        </td>
                        {selected !== "Requested" &&
                          <>
                            <td className={`px-6 py-4 whitespace-nowrap `}>
                              <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  setEditData(doctor);
                                  setOpen(true);
                                }}
                              >
                                <TbUserEdit />
                              </div>
                            </td>
                            <td className={`px-6 py-4 whitespace-nowrap`}>
                              {!doctor?.delete_request ? <div
                                className="text-sm text-gray-900 capitalize cursor-pointer"
                                onClick={() => {
                                  delDoctor(doctor?._id);
                                }}
                              >
                                <MdOutlineDeleteOutline color="red" />
                              </div> : <button disabled className="text-sm rounded-full bg-primary text-white px-2 py-1">Requested</button>}
                            </td>
                          </>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound data={"doctors"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorsInformation;
