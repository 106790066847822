import React, { useState } from "react";
// import AppointmentModebuttons from "./AppointmentModebuttons";
import ConsultationAppointmentForm from "./ConsultationAppointmentForm";
// import LabApppointmentForm from "./LabAppointmentForm";

const AppointmentModal = ({
  appointmentDetails,
  is_follow_up,
  is_re_visit,
  mode,
  setOpen,
  setDatasFn = null,
}) => {
  const [appointmentMode] = useState(
    is_follow_up && is_re_visit ? mode : "consultancy"
  );
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className={`flex  w-full max-w-screen-lg overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col`}
      >
        {/* {!appointmentMode && (
          <AppointmentModebuttons
            setOpen={setOpen}
            appointmentMode={appointmentMode}
            setAppointmentMode={setAppointmentMode}
          />
        )} */}
        {appointmentMode && appointmentMode === "consultancy" && (
          <ConsultationAppointmentForm
            setOpen={setOpen}
            setDatasFn={setDatasFn}
            appointmentDetails={appointmentDetails}
            is_follow_up={is_follow_up}
            is_re_visit={is_re_visit}
          />
        )}
        {/* {appointmentMode && appointmentMode === "lab" && (
          <LabApppointmentForm
            setOpen={setOpen}
            setDatasFn={setDatasFn}
            appointmentDetails={appointmentDetails}
            is_follow_up={is_follow_up}
          />
        )} */}
      </div>
    </div>
  );
};

export default AppointmentModal;
