import React, { useEffect, useState } from "react";
import { getPurchasedCards } from "../../utils/bookConsultation/ConsultationUtils";
import { NoDataFound, SearchInput } from "../Common";

const HaveCard = ({ haveCard, selectedOffer, cardId, handleOfferClick }) => {
  const [purchasedCards, setPurchasedCards] = useState([]);

  useEffect(() => {
    if (haveCard && !purchasedCards.length) {
      getPurchasedCards()
        .then((res) => setPurchasedCards(res?.data?.data))
        .catch((err) => console.log(err));
    }
  }, [haveCard, purchasedCards.length]);

  const [filteredCards, setFilteredCards] = useState([]);
  const [cardQuery, setCardQuery] = useState("");

  useEffect(() => {
    const filteredData = purchasedCards?.filter((card) => {
      const numberMatch = card?.card_number
        ?.toLowerCase()
        .includes(cardQuery.toLowerCase());
      const phoneMatch = card?.user_id?.phone_number
        ?.toLowerCase()
        .includes(cardQuery.toLowerCase());
      return numberMatch || phoneMatch;
    });
    setFilteredCards(filteredData);
  }, [cardQuery, purchasedCards]);

  // Utility function to check if the card is expired
  const isCardExpired = (expiryDate) => {
    return new Date(expiryDate) < new Date();
  };

  return (
    <>
      <div className="flex w-full h-fit justify-between gap-5">
        <SearchInput
          placeholder={"Search card by Number, Phone"}
          searchQuery={cardQuery}
          setSearchQuery={setCardQuery}
        />
      </div>
      {filteredCards?.length > 0 ? (
        <div className="w-full min-h-[250px] overflow-y-auto">
          {filteredCards?.map((item, idx) => {
            const isExpired = isCardExpired(item?.expiry_date);

            return (
              <div
                key={idx}
                className={`flex flex-col gap-5 p-4 bg-white rounded-md mb-2 ${
                  isExpired ? "border-2 border-red-600" : ""
                }`}
              >
                {isExpired && (
                  <div className="text-red-600 font-semibold mb-2">
                    This card has expired!
                  </div>
                )}
                <div className="flex gap-2 md:gap-5 md:flex-row flex-col flex-wrap">
                  <span>
                    Card Number : <b>{item?.card_number}</b>
                  </span>
                  <span>
                    Patient Name : <b>{item?.user_id?.name}</b>
                  </span>
                  <span>
                    Patient ID : <b>{item?.user_id?.patient_id}</b>
                  </span>
                  <span>
                    Phone : <b>{item?.user_id?.phone_number}</b>
                  </span>
                  <span>
                    Credits Points : <b>{item?.credit_point || 0}</b>
                  </span>
                  <span>
                    Usable Points : <b>{item?.doctor_using_credit || 0}</b>
                  </span>
                </div>
                {!isExpired && (
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2.5">
                      <span>Use Credit Points </span>
                      <div className="flex gap-2.5 flex-wrap max-h-[100px] overflow-y-auto">
                        {item?.credit_point < item?.doctor_using_credit ||
                        !item?.credit_point ? (
                          <span className="text-red-600 text-sm">
                            Insufficient Points!
                          </span>
                        ) : (
                          <div
                            className={`flex py-1.5 h-fit px-2.5 rounded-md text-white cursor-pointer text-sm ${
                              cardId === item?._id &&
                              selectedOffer?.type === "points"
                                ? "bg-blue-600"
                                : "bg-green-600"
                            }`}
                            onClick={() =>
                              handleOfferClick(
                                item?._id,
                                item?.user_id?._id,
                                "points",
                                0,
                                false,
                                null,
                                item?.doctor_using_credit || 0,
                                item?.card_id?.deductionRate || 0
                              )
                            }
                          >
                            Use
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <span>Free Offers</span>
                      <div className="flex gap-2.5 flex-wrap max-h-[100px] overflow-y-auto">
                        {Array.from(
                          { length: item?.card_id?.free_offers || 0 },
                          (_, index) => {
                            const isSelected =
                              cardId === item?._id &&
                              selectedOffer?.type === "free" &&
                              selectedOffer?.index === index;
                            const isUsed = index >= item?.free_offers_used;
                            return {
                              status: isSelected
                                ? "Selected"
                                : isUsed
                                ? "Used"
                                : "Available",
                              bgColor: isSelected
                                ? "bg-blue-600"
                                : isUsed
                                ? "bg-red-600"
                                : "bg-green-600",
                              isUsed,
                            };
                          }
                        ).map((offer, index) => (
                          <div
                            key={index}
                            className={`flex py-1.5 h-fit px-2.5 rounded-md text-white cursor-pointer text-sm ${offer.bgColor}`}
                            onClick={() =>
                              !offer.isExpired &&
                              handleOfferClick(
                                item?._id,
                                item?.user_id?._id,
                                "free",
                                index,
                                offer.isUsed
                              )
                            }
                          >
                            {offer.status}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <span>Discount Available</span>
                      <div className="flex gap-2.5 flex-wrap  max-h-[100px] overflow-y-auto">
                        {Array.from(
                          { length: item?.card_id?.discount_used || 0 },
                          (_, index) => {
                            const isSelected =
                              cardId === item?._id &&
                              selectedOffer?.type === "discount" &&
                              selectedOffer?.index === index;
                            const isUsed = index >= item?.discount_used;
                            const discount = item?.card_id?.discount_percentage;
                            return {
                              status: isSelected
                                ? "Selected"
                                : isUsed
                                ? "Used"
                                : `${discount} % off`,
                              bgColor: isSelected
                                ? "bg-blue-600"
                                : isUsed
                                ? "bg-red-600"
                                : "bg-green-600",
                              isUsed,
                              discount,
                            };
                          }
                        ).map((offer, index) => (
                          <div
                            key={index}
                            className={`flex py-1.5 h-fit px-2.5 rounded-md text-white cursor-pointer text-sm ${offer.bgColor}`}
                            onClick={() =>
                              handleOfferClick(
                                item?._id,
                                item?.user_id?._id,
                                "discount",
                                index,
                                offer.isUsed,
                                offer.discount
                              )
                            }
                          >
                            {offer.status}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-white">
          <NoDataFound data={"health cards"} />
        </div>
      )}
    </>
  );
};

export default HaveCard;
