import React, { useCallback, useEffect, useState } from 'react'
import "./DoctorsMainSection.css"
import { FaUserDoctor } from "react-icons/fa6";
import DoctorsTable from './DoctorsTable';
import AddAndEditModal from './AddAndEditModal';
import { getDoctors } from '../../utils/doctorUtils/doctorUtils';
import { SearchInput } from '../Common';
import RequestMode from './RequestMode/RequestMode';
import { useSelector } from "react-redux";

const DoctorsMainSection = () => {
  const user = useSelector((state) => state.userReducer.hospital);
  const [selected, setSelected] = useState(`Approved`);
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [doctors, setdoctors] = useState([]);
  const setDoctorsFn = useCallback(() => {
      getDoctors(selected,user?._id)
        .then((res) => setdoctors(res.data.data))
        .catch((err) => console.log(err));
  },[selected, user?._id]);
  useEffect(() => {
    setDoctorsFn();
  }, [setDoctorsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdDoctors, setFilteredDoctors] = useState([]);
  useEffect(() => {
    const filteredData = doctors?.filter((doctor) => {
      const nameMatch = doctor?.name?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const idMatch = doctor?.doctor_id?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || idMatch;
    });
    setFilteredDoctors(filteredData);
  }, [doctors, searchQuery]);
  return (
    <>
      {open && (
        <AddAndEditModal
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          setDoctorsFn={setDoctorsFn}
        />
      )}
      <div className="dp-header">
        <div className="flex w-fit h-fit text-xl font-semibold">Doctors</div>
        <div className="dp-header-search-container">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Name, Id"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="dp-info-container">
        <div className="dp-info-header">
          <RequestMode selected={selected} setSelected={setSelected} />
          <button
            style={{
              background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
            }}
            className={`doctor-add-button`}
            onClick={() => setOpen(true)}
          >
            <span>
              <FaUserDoctor />
            </span>
            <span>Doctor</span>
          </button>
        </div>
        <DoctorsTable
          setEditData={setEditData}
          selected={selected}
          setOpen={setOpen}
          doctors={filterdDoctors}
          setDoctorsFn={setDoctorsFn}
        />
      </div>
    </>
  );
}

export default DoctorsMainSection