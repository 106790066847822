import { axiosAuthorized } from "../../api/config"

export const getDoctors = async (mode = "Requested", userId) => {
  try {
    let url = `/doctor/unverifydoctor/${userId}`;
    if (mode === 'Approved') {
      url = `/doctor/verify_doctor/${userId}`;
    }
    const response = await axiosAuthorized.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addDoctor = async (initialState, department_id, degreeData, specialities, profileImage, coverImage, meta_tag, hospital_id, doctorId = null,) => {
  try {
    department_id = department_id?.map((item) => item?._id);
    const data = {
      name: initialState.name,
      age: initialState.age,
      gender: initialState.gender,
      department_id,
      experience: initialState.experience,
      qualification: degreeData,
      speciality: specialities,
      region: initialState.region,
      email: initialState.email,
      phone_number: initialState.phone,
      meta_name: initialState.meta_name,
      meta_description: initialState.meta_description,
      meta_tag,
      hospital_id,
    };
    const requestData = doctorId ? data : {
      ...data,
      doctor_image: profileImage,
      doctor_cover_image: coverImage,
    };
    const requestConfig = {
      headers: { "Content-Type": doctorId ? "application/json" : "multipart/form-data" },
    };

    const url = doctorId
      ? `/doctor/update/${doctorId}`
      : `/doctor/add_doctor`;
    const mainRequest = axiosAuthorized[doctorId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (doctorId && profileImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/profile_update/${doctorId}`, { doctor_image: profileImage, }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (doctorId && coverImage instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/doctor/update_doctor/${doctorId}`, { doctor_cover_image: coverImage }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteDoctor = async (doctorId) => {
  try {
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosAuthorized.put(`/doctor/update_doctor/${doctorId}`, { delete_request: true }, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}