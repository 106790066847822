import React, { useState } from 'react'
import { useForm } from '../../hooks';
import { SelectInput, TextInput } from '../Common/FormComponents';
import { validateRegisterForm } from '../../utils/registerUtils/validateRegisterForm';
import { toast } from "react-toastify";
import { addRegister } from '../../utils/registerUtils/registerUtil';

const RegisterForm = ({setResponse, setOtpSended, setOpen}) => {
  const initialState = {
    name: "",
    phone_number: "",
    email_id: "",
    gender: "Select Gender",
    // social_media: "Select Platform",
  };
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [err, setError] = useState({ global: "" });
  const [loading, setLoading] = useState(false);
  const handleRegister = (e) => {
    e.preventDefault();
    if (validateRegisterForm(formData, setError)) {
      setError({ global: "" });
      setLoading(true);
      addRegister(formData)
        .then((res) => {
          setResponse(res.data.data);
          setOtpSended(true);
        })
        .catch((err) => {
          if (err.response) {
            const errorMessage = err.response?.data || err.message;
            if (
              errorMessage.includes("E11000 duplicate key error collection")
            ) {
              toast(`Email already registered`, {
                type: "error",
                autoClose: 3000,
              });
            } else {
              toast(`An error occurred. Please try again.`, {
                type: "error",
                autoClose: 3000,
              });
            }
          } else {
            toast(`An error occurred. Please try again.`, {
              type: "error",
              autoClose: 3000,
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <div className="flex w-full h-full justify-center items-center flex-col gap-5 py-5">
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Name"
          formName={"name"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <SelectInput
          data={formData}
          handleInput={handleInput}
          items={["Select Gender", "Male", "Female"]}
          name={"Gender"}
          formName={"gender"}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Phone Number"
          formName={"phone_number"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TextInput
          name="Email"
          notShow={true}
          formName={"email_id"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        {/* <SelectInput
          name="Platform"
          formName={"social_media"}
          items={[
            "Select Platform",
            "Facebook",
            "Instagram",
            "Website",
            "Snapchat",
            "LinkedIn",
            "X",
            "Google",
          ]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        /> */}
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              clearForm();
              setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={loading}
            onClick={(e) => {
              handleRegister(e);
            }}
          >
            {loading ? "Please Wait..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm