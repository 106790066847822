import { useEffect, useState } from "react";
import classNames from "classnames";
import { FiLogOut } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../redux/userSlice";
import { useDispatch } from "react-redux";

export const NavDrop = ({ user = null, links }) => {
  const dropDownItemsClasses = classNames(
    "text-[13px]",
    "font-normal",
    "p-2",
    "hover:bg-gray-100",
    "flex",
    "flex-row",
    "gap-1.5",
    "items-center"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };
  const [drop, setDrop] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest("#drop-down")) {
        setDrop(false);
      }
    };
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={"flex flex-row items-center gap-5  h-12 w-12 relative"}
        id={"drop-down"}
      >
        <div
          className={
            "h-full w-full border-accent-color-one profile-picture cursor-pointer"
          }
          onClick={() => setDrop((state) => !state)}
        >
          <img
            src={user?.profile_image}
            alt={"profile_image"}
            className={
              "object-cover h-full w-full   rounded-full border border-accent-color-one"
            }
          />
        </div>
        {drop && (
          <div
            className={`navbar-dropdown z-50 absolute top-[63px] md:top-[79px] -right-6 md:right-0   bg-white shadow-md rounded 
          flex flex-col w-screen md:w-fit min-w-[200px]`}
            id={"second-component"}
          >
            <div
              className={
                "flex flex-row w-min justify-center gap-2.5 border p-2.5 rounded m-2.5"
              }
            >
              <div style={{ width: "40px", height: "40px" }}>
                <img
                  src={user?.profile_image}
                  alt={"profile_image"}
                  className={
                    "object-cover rounded h-full w-full border-accent-color-one"
                  }
                />
              </div>
              <div className={"w-max"}>
                <p className={"text-[11px]"}>Logged in as</p>
                <p className={"text-[13px] "}>{user?.hospital_name}</p>
              </div>
            </div>
            <div className={"w-full flex flex-col cursor-pointer lg:hidden"}>
              {links.map(({ to, destination }, index) => (
                <NavLink to={to} key={index} className={dropDownItemsClasses}>
                  <div>{destination}</div>
                </NavLink>
              ))}
              <div
                className={dropDownItemsClasses + " text-[#FF6C6C]"}
                onClick={handleLogout}
              >
                <FiLogOut />
                Logout
              </div>
            </div>
            <div className={"w-full hidden flex-col cursor-pointer lg:flex"}>
              <div
                className={dropDownItemsClasses + " text-[#FF6C6C]"}
                onClick={handleLogout}
              >
                <FiLogOut />
                Logout
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
