export const validateRegisterForm = (initialState, setError) => {
  const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const genderPattern = /^(?!Select Gender$).+/;
  const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

  if (!initialState.name.trim().length) {
    setError({ global: "Invalid Name" });
    return false;
  }

  if (!phoneNumberPattern.test(initialState.phone_number)) {
    setError({ global: "Invalid Phone Number" });
    return false;
  }

  if (initialState.email_id.length && (!emailPattern.test(initialState.email_id))) {
    setError({ global: "Invalid Email" });
    return false;
  }


  if (!genderPattern.test(initialState.gender)) {
    setError({ global: "Please Select Gender" });
    return false;
  }

  if (initialState.status === "Select Status") {
    setError({ global: "Please Select Payment Status" });
    return false;
  }
  return true;
}