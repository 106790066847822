import { axiosAuthorized } from "../../api/config";

export const getAboutUs = async (hospitalId) => {
  try {
    const response = await axiosAuthorized.get(`/hospital/gethospitalaboutbyhospitalid/${hospitalId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addAboutUs = async (initialState, timings, specialities, procedure, hospital_image, hospital_logo, hospital_id, aboutId = null) => {
  try {
    const data = {
      ...initialState,
      timings,
      no_of_beds: Number.parseInt(initialState?.no_of_beds) || 0,
      no_of_doctors: Number.parseInt(initialState?.no_of_doctors) || 0,
      review_point: Number.parseFloat(initialState?.review_point) || 0,
      specialities,
      procedure,
      hospital_id
    }
    const requestData = aboutId ? data : { ...data, hospital_image, hospital_logo };
    
    const requestConfig = {
      headers: { "Content-Type": aboutId ? "application/json" : "multipart/form-data" },
    };
    console.log(requestConfig, aboutId, hospital_image, hospital_logo);
    const url = aboutId
      ? `/hospital/update_hospitalabout/${aboutId}`
      : `/hospital/create_hospitalabout`;
    const mainRequest = axiosAuthorized[aboutId ? 'put' : 'post'](url, requestData, requestConfig);
    const imageRequests = [];
    if (aboutId && hospital_image instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/hospital/update_hospitalimageabout/${aboutId}`, { hospital_image }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    if (aboutId && hospital_logo instanceof File) {
      imageRequests.push(
        axiosAuthorized['put'](`/hospital/update_hospitallogoabout/${aboutId}`, { hospital_logo }, { headers: { "Content-Type": "multipart/form-data" } })
      );
    }
    const response = await mainRequest;
    await Promise.all(imageRequests);
    return response;
  } catch (error) {
    throw error;
  }
}